import React from "react";
import { Link, withPrefix } from "gatsby";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";

import * as pageStyles from "../global-styles/page.module.css";

export const BlankStaffPaper = (props: PageComponentProps) => {
  const { clairnoteName, urlDir } = props;
  const title = "Blank Staff Paper";
  const description = `Blank staff paper for writing music by hand in ${clairnoteName} music notation. Download PDF files with two, three, or four-octave staves.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <div>
          <p>
            Print the following PDF files to write music by hand in{" "}
            {clairnoteName}. The first file has standard two-octave{" "}
            {clairnoteName} staves, the second has three-octave staves, and the
            third has four-octave "grand staves" for piano. You can also
            download the LilyPond files that were used to generate each PDF
            file. They can be used to produce blank staff paper with larger or
            smaller staves and different numbers of staves per page. (The
            LilyPond files require the <code>clairnote.ly</code> file. See{" "}
            <Link to={urlDir + "software/"}>Software: LilyPond</Link>
            .)
          </p>
          <p>
            <strong>Two-Octave {clairnoteName} Staves</strong>
          </p>
          <p>
            <a
              href={withPrefix(
                "pdf/blank-staff-paper/Blank-staff-paper-Clairnote-2octave.pdf"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF File
            </a>
            <br />
            <a
              href={withPrefix(
                "pdf-ly/blank-staff-paper/Blank-staff-paper-Clairnote-2octave.ly"
              )}
            >
              LilyPond File
            </a>
          </p>
          <p>
            <strong>Three-Octave {clairnoteName} Staves</strong>
          </p>
          <p>
            <a
              href={withPrefix(
                "pdf/blank-staff-paper/Blank-staff-paper-Clairnote-3octave.pdf"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF File
            </a>
            <br />
            <a
              href={withPrefix(
                "pdf-ly/blank-staff-paper/Blank-staff-paper-Clairnote-3octave.ly"
              )}
            >
              LilyPond File
            </a>
          </p>
          <p>
            <strong>Four-Octave {clairnoteName} Staves (for Piano)</strong>
          </p>
          <p>
            <a
              href={withPrefix(
                "pdf/blank-staff-paper/Blank-staff-paper-Clairnote-4octave-piano.pdf"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF File
            </a>
            <br />
            <a
              href={withPrefix(
                "pdf-ly/blank-staff-paper/Blank-staff-paper-Clairnote-4octave-piano.ly"
              )}
            >
              LilyPond File
            </a>
          </p>
        </div>
      </article>
    </Layout>
  );
};
